import "../wdyr";

import React from "react";

import {
  Hero,
  Pricing,
  Footer,
  GiftCard,
  Navbar,
  SEO,
} from "../components";

const IndexPage = () => {

  return (
    <main className="w-full">
      <SEO />
      <Navbar />
      <Hero />
      <Pricing />
      <GiftCard />
      <Footer />
    </main>
  );
};

export default IndexPage;
